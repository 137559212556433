// @ts-ignore
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useEffect, useContext, useState, useRef } from "react";
import Header from "../../common/header/Header";
import { CheckGreenSvg, FailureSvg, SuccessSvg, WarningSvg } from "utils/icons";
import { ACTION_STATUS, PATHS, privacyPolicyLink, USER_KYC_STATUS, VERIFF_DISPLAY } from "utils/consts";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { finishKYC, verifyUserKycStatusByUuid, veriffSessionSuccess } from "store/slices/userSlice";
import { Buffer } from 'buffer';
import Button from "components/common/buttons/Button";
import WarningIcon from "assets/icons/WarningIcon";

interface IProps {
  veriffUuid?: string;
  nextStep: () => void;
}




const VeriffSession: React.FC<IProps> = (props) => {

  const [isVeriffFinished, setIsVeriffFinished] = useState(false);

  const { veriffUuid, nextStep } = props;


  useEffect(() => {

    if (isVeriffFinished) {
      nextStep()
    }

  }, [isVeriffFinished]);


  useEffect(
    () => {
      const veriff = Veriff({
        host: process.env.REACT_APP_VERIFF_SERVER_API,
        apiKey: process.env.REACT_APP_VERIFF_API_PUBLIC_KEY,
        parentId: 'veriff-root',
        onSession: function (err: any, response: any) {
          createVeriffFrame({
            url: response.verification.url,
            onEvent: function (msg: string) {
              switch (msg) {
                case MESSAGES.FINISHED:
                  //dispatch(veriffSessionSuccess())
                  //navigate(PATHS.BUY);
                  setIsVeriffFinished(true);
                  break;
              }
            }
          });
        }
      });
      veriff.setParams(
        {
          person: {
            givenName: ' ',
            lastName: ' ',
          },
          vendorData: veriffUuid
        });
      veriff.mount({
        submitBtnText: 'Start Verification',
        loadingText: 'Please wait...'
      });
    }
    , []);

  return (
    <>
      <Header title="Verify Your Identity" />
      <div>
        <h2 className="text-h2 font-bold text-font-primary">Create Your Account</h2>
        <span className="text-base font-medium text-font-primary mt-2">
          FitechOne Sp.zoo. would like to confirm your identity.
        </span>
        <div className="mt-8">
          <span className="text-base font-bold text-font-primary">BEFORE YOU START, PLEASE:</span>
          <div className="flex mt-8">
            <img src={CheckGreenSvg} />
            <span className="text-base font-medium text-font-primary ml-4">Have to hand a valid Government issued identity document.</span>
          </div>
          <div className="flex mt-4">
            <img src={CheckGreenSvg} />
            <span className="text-base font-medium text-font-primary ml-4">Make sure you are in a well-lit room.</span>
          </div>
          <div className="flex mt-4">
            <img src={CheckGreenSvg} />
            <span className="text-base font-medium text-font-primary ml-4">Be prepared to take a selfie and photos of your ID.</span>
          </div>
          <p className="text-base font-medium text-font-primary mt-8 mb-8">Providing feedback is voluntary, to help improve Veriff’s services. We will use automation to conduct your identity verification. Read more about personal data processing in Veriff’s <a className="text-primary" href={privacyPolicyLink} target='_blank'>Privacy Policy</a>.</p>
        </div>
      </div>
      <div className="w-full !max-w-none" id='veriff-root'></div>
    </>
  )

}

export default VeriffSession;

