import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import BuyCryptoSummary from "components/common/BuyCryptoSummary";
import CheckBox from "components/common/inputs/CheckBox";
import { ellipsisAddress, getFiat } from "utils/supports";
import { ACQUIRERS, cryptoSvgs, merchantId, PATHS, RAMPING, termsOfServiceLink } from "utils/consts";
import PendingIcon from "assets/icons/PendingIcon";
import { IOrder } from "types";
import { apiServerClient } from "utils/apiClient";
import { CancelInCircleSvg, PaymentLogo3 } from "utils/icons";

const ConfirmOrder: React.FC = () => {
  const dispatch = useDispatch();
  const { crypto, currency, user, getCardDetail } = useContext(PlatformContext);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [clicked] = useState<boolean>(false);
  const { currentOrder, creatingOrder } = useSelector((state: RootState) => state.order);

  const { quote } = useSelector((state: RootState) => state.quote);
  const { amount } = useSelector((state: RootState) => state.crypto);
  const { walletAddress } = useSelector((state: RootState) => state.user);
  const { fee } = useSelector((state: RootState) => state.fee);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const onClickContinue = () => {
    setErrorMessage('');
    if (confirmation) {
      dispatch(AppActions.order.updateOrder({
        orderUuid: currentOrder?.orderUuid,
        cryptoAmount: amount,
        fiatAmount: getFiat(amount, quote, fee.fee, 0),
        liquidityQuote: quote.liquidityQuotes,
        processingFee: fee.fee,
        status: currentOrder?.status,
        acquirerType: ACQUIRERS.CHECKOUT,
        next: async () => {
          setIsLoading(true);

          const { cardInfo, billingAddress, cardHolderName } = getCardDetail();
          try {
            const response = await apiServerClient.post('payment/create-payment', {
              type: "sso_login",
              merchantId: merchantId,
              paymentType: "card",
              orderUuid: currentOrder?.orderUuid,
              cardInfo,
              currency: currency,
              issuerCountry: billingAddress.country,
              billingAddress,
              successUrl: `${window.location.origin}${PATHS.HISTORY}/${currentOrder?.orderUuid}`,
              failureUrl: `${window.location.origin}`,
              customer: {
                email: user?.email,
                name: cardHolderName,
              },
            });
      
            if (
              response &&
              response.data.redirectUrl
            ) {
              window.location.replace(response.data.redirectUrl);
              return;
            }
            navigate(`${PATHS.HISTORY}/${currentOrder?.orderUuid}`);
          } catch (err: any) {
            let message = "It has not been possible to place your payment at this time. Please try again or contact the Customer Support";
            if (
              err.response &&
              err.response.data &&
              err.response.data.data &&
              err.response.data.data.redirectUrl
            ) {
              window.location.replace(err.response.data.data.redirectUrl);
              return;
            }
            if (err.response.data.data && err.response.data.data.message) {
              message = err.response.data.data.message;
            }
            setErrorMessage(message);
            return;
          } finally {
            setIsLoading(false);
          }
        }
      }));
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(walletAddress);
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Header title="Confirm Your Order" backTo={PATHS.BUY} />
      {
        !!errorMessage &&
        <div className="flex items-center bg-feedback-warning rounded-xl mx-4 px-4 py-2 mb-4" onClick={onCloseWarning}>
          <img src={CancelInCircleSvg} />
          <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
        </div>
      }
      <div className="flex flex-col h-full justify-between p-4 mt-4">
        <div className="flex flex-col">
          <span className="text-base font-medium text-font-subtle">Delivering {crypto} to</span>
          <div className="flex justify-between mt-2 mb-4">
            <div className="flex">
              <img className="w-6 h-6" src={cryptoSvgs[crypto]} />
              <span className="text-base font-medium text-font-primary ml-2">{ellipsisAddress(walletAddress)}</span>
            </div>
            <span className="text-sm font-bold text-primary" onClick={onCopy}>Copy</span>
          </div>
          <div className="flex justify-between items-center mt-2 mb-8">
            {
              <div className="flex items-center">
                <img src={PaymentLogo3} />&nbsp;
                <span className="text-base font-bold text-font-primary">{getCardDetail().cardInfo.cardNumber}</span>
              </div>
            }
            <Link to={`${PATHS.BUY}${PATHS.CARD_INFO}`}><a className="text-sm font-bold text-primary">Change</a></Link>
          </div>
          <BuyCryptoSummary
            currency={currency}
            crypto={crypto}
            type={RAMPING.ON}
            amount={amount}
          />
        </div>
        <div
          className="w-full mt-4"
        >
          <div className="flex items-center mb-4">
            <CheckBox className="mr-4" error={!confirmation && clicked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setConfirmation(e.target.checked); }} />
            <p className="text-sm font-medium text-font-primary">I agree to FitechOne Sp.zoo.’s <a className="text-blue-500 underline" href={termsOfServiceLink} target='_blank'>Terms of Use</a> and I authorise FitechOne Sp.zoo. to debit my chosen payment method for the amount above on today’s date and understand that this can not be cancelled, recalled or refunded.</p>
          </div>
          {
            !confirmation && clicked && <p className="text-sm font-medium text-feedback-warning mb-4">You must check this box to confirm your agreement with the Terms of Use to proceed.</p>
          }
          <Button
            className="w-full flex justify-center"
            disabled={!confirmation || creatingOrder || isLoading}
            onClick={onClickContinue}
          >
            {
              creatingOrder || isLoading ?
              <div className={`flex items-center justify-center w-9 h-9 rounded-full z-20 animate-spin-slow`} >
                <PendingIcon width={36} height={36} />
              </div>:
              'Proceed with Order'
            }
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;