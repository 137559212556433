import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Button from "components/common/buttons/Button";
import ConfirmPassword from "components/common/inputs/ConfirmPassword";
import { FitechOneLogo } from "utils/icons";
import { resetPassword, verifyForgotPassword } from "store/slices/userSlice";
import { RootState } from "store";
import { PATHS } from "utils/consts";
import PasswordUpdated from "components/common/popups/PasswordUpdated";

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) navigate(PATHS.BUY);
    else dispatch(verifyForgotPassword({ token: token as string, error: () => {
      navigate(PATHS.BUY);
    }}));
  }, []);

  const onClickContinue = () => {
    const token = searchParams.get("token");
    if (!token) {
      navigate(PATHS.BUY);
      return;
    }

    dispatch(resetPassword({
      password,
      token,
      next: () => {
        setOpen(true);
      }
    }));
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  return (
    <>
      <div className="p-2">
        <img className="h-10" src={FitechOneLogo} />
      </div>
      <div className="p-4">
        <h2 className="text-h2 font-bold text-font-primary">New Password</h2>
        <ConfirmPassword
          className="mt-8"
          onChange={onChangePassword}
        />
        <Button
          className="w-full mt-8"
          disabled={!password}
          onClick={onClickContinue}
        >
          <>Reset Password</>
        </Button>
        {
          isOpen && <PasswordUpdated
            onClose={() => { setOpen(false); }}
          />
        }
      </div>
    </>
  );
};

export default ResetPassword;