import { useContext, useState, useEffect } from "react";
import { cookiePolicyLink, cryptoSvgs, getCurrencySvg, PATHS, RAMPING } from "utils/consts";

import Button from "../common/buttons/Button";
import Input from "../common/inputs/Input";
import SelectButton from "../common/buttons/SelectButton";
import { SelectCryptoPopup, SelectCurrencyPopup } from "../common/popups";
import { useNavigate, useSearchParams } from "react-router-dom";
import BuyCryptoSummary from "components/common/BuyCryptoSummary";
import { useDispatch, useSelector } from "react-redux";
import { getCrypto, getFiat } from "utils/supports";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import { TransactionTypeForFee } from "types";
import { SelectFeeType } from "components/common/selects";
import { quoteApi } from "api";

const BuyCrypto: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params] = useSearchParams();

  const referralCode = params.get('promoCode') || '';

  const { currency, crypto, saveCurrency, saveCrypto, getLoggedIn } = useContext(PlatformContext);
  const { fee } = useSelector((state: RootState) => state.fee);
  const { amount, cryptos } = useSelector((root: RootState) => root.crypto);
  const { quote } = useSelector((state: RootState) => state.quote);
  const [ include, setInclude ] = useState<boolean>(true)
  const [isCryptoOpen, setCryptoOpen] = useState<boolean>(false);
  const [isFiatOpen, setFiatOpen] = useState<boolean>(false);
  const [fiatAmount, setFiatAmount] = useState<string>(amount ? getFiat(amount, quote, fee.fee, 0) : '100');
  const [cryptoAmount, setCryptoAmount] = useState<string>(amount.toString());

  const [minCryptoLimit, setMinCryptoLimit] = useState(0);
  const [maxCryptoLimit, setMaxCryptoLimit] = useState(0);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (getLoggedIn()) {
      dispatch(AppActions.fee.getFee({
        sendAmount: Number(fiatAmount),
        currency: currency,
        transactionType: TransactionTypeForFee.RETAIL_ON_RAMP
      }));
    }
  }, [fiatAmount, currency])

  const onChangeFiatAmount = (value: string) => {
    setFiatAmount(value);
    let newValue = +value;
    if(!include) newValue = +newValue + +fee.fee;
    setCryptoAmount(getCrypto(newValue || 0, quote, fee.fee, 0));
  }

  const onChangeCryptoAmount = (value: string) => {
    setCryptoAmount(value);
    setFiatAmount(getFiat(parseFloat(value || '0'), quote, fee.fee, 0));
  }

  const onClickContinue = () => {
    dispatch(AppActions.fee.setIncluded(include));
    dispatch(AppActions.crypto.setAmount(parseFloat(cryptoAmount || '0')));

    navigate(`${PATHS.BUY}${PATHS.WALLET_ADDRESS}${referralCode.length ? '?promoCode='+referralCode : ''}`);
  }

  useEffect(() => {
    let newValue = +fiatAmount
    if(!include) newValue += +fee.fee
    setCryptoAmount(getCrypto(newValue || 0, quote, fee.fee, 0));
  }, [quote, include]);

  useEffect(() => {
    if (crypto) {
      setLoading(true);
      const ticker = cryptos.find(el => el.shortName === crypto)?.ticker;
      ticker && quoteApi.getQuote({
        crypto: ticker,
        currency: "EUR",
        quantity: 1,
        side: "buy",
      }).then((data) => {
        const { liquidityQuotes, liquidityPool }: any = data;

        setMinCryptoLimit(+getCrypto(50, {
          liquidityQuotes,
          liquidityProvider: liquidityPool,
          subscriptionFee: 0,
        }, fee.fee, 0));

        setMaxCryptoLimit(+getCrypto(350, {
          liquidityQuotes,
          liquidityProvider: liquidityPool,
          subscriptionFee: 0,
        }, fee.fee, 0));
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });;
    }
  }, [crypto, cryptoAmount]);

  return (
    <div className="flex flex-col justify-between h-full pl-4 pr-4 pt-8 pb-4">
      <div>
        <label className="text-base font-medium text-font-primary">Spend</label>
        <div className="flex mt-2 mb-8">
          <Input
            className="w-full mr-2"
            type="number"
            label={"Amount"}
            value={fiatAmount}
            onChange={onChangeFiatAmount}
          />
          <SelectButton
            className="w-60"
            text={currency}
            svgPath={getCurrencySvg(currency)}
            label={"Currency"}
            onClick={() => { setFiatOpen(true); }}
          />
        </div>
        <label className="text-base font-medium text-font-primary">Receive</label>
        <div className="flex mt-2 mb-8">
          <Input
            className="w-full mr-2"
            type="number"
            label={"Amount"}
            value={cryptoAmount}
            onChange={onChangeCryptoAmount}
          />
          <SelectButton
            className="w-60"
            text={crypto}
            svgPath={cryptoSvgs[crypto]}
            label={"Crypto"}
            onClick={() => { setCryptoOpen(true); }}
          />
        </div>

        <SelectFeeType className="mb-4" value={include ? 'include' : 'exclude'} onSelectChange={setInclude} />

        <BuyCryptoSummary
          currency={currency}
          crypto={crypto}
          type={RAMPING.ON}
          amount={parseFloat(cryptoAmount || '0')}
        />
      </div>

      <div className="flex flex-col items-center w-full mt-4">
        {
          +cryptoAmount > maxCryptoLimit && +cryptoAmount !=0 && !isLoading &&
          <p className="text-sm font-medium text-feedback-warning mb-2">Exceeds maximum order limit, please try a smaller amount.</p>
        }
        {
          +cryptoAmount < minCryptoLimit && +cryptoAmount !=0 && !isLoading &&
          <p className="text-sm font-medium text-feedback-warning mb-2">Under minimum order limit, please try a larger amount.</p>
        }
        <Button
          className="w-full"
          disabled={
            parseFloat(cryptoAmount) == 0 ||
            parseFloat(fiatAmount) == 0 ||
            parseFloat(fiatAmount) < fee.fee ||
            !(+cryptoAmount >= minCryptoLimit && +cryptoAmount <= maxCryptoLimit)
          }
          onClick={onClickContinue}
        >
          Continue
        </Button>
        <span className="text-xs font-medium text-font-primary mt-2">By continuing you agree to our <a className="underline underline-offset-2" href={cookiePolicyLink} target="_blank">cookie policy</a>.</span>
      </div>
      {
        isCryptoOpen &&
        <SelectCryptoPopup
          crypto={crypto}
          onChange={crypto => saveCrypto(crypto)}
          onClose={() => { setCryptoOpen(false); }}
        />
      }
      {
        isFiatOpen &&
        <SelectCurrencyPopup
          type={0}
          currency={currency}
          onChange={currency => saveCurrency(currency)}
          onClose={() => { setFiatOpen(false); }}
        />
      }
    </div>
  );
};

export default BuyCrypto;
