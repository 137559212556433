import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { getCurrencySvg, PATHS, RAMPING, termsOfServiceLink } from "utils/consts";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { PlatformContext } from "contexts";
import BuyCryptoSummary from "components/common/BuyCryptoSummary";
import CheckBox from "components/common/inputs/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { getBankInputFields, getFiat } from "utils/supports";
import { CancelInCircleSvg, PaymentLogo1, PaymentLogo2, PaymentLogo3 } from "utils/icons";
import PendingIcon from "assets/icons/PendingIcon";

const ConfirmOrder: React.FC = () => {
  const dispatch = useDispatch();

  const { currency, crypto, user, getUser } = useContext(PlatformContext);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [error, setError] = useState('');

  const { cards } = useSelector((root: RootState) => root.card);
  const { createdOrder, creatingOrder, currentOrder, validatedCardTokenId } = useSelector((state: RootState) => state.order);
  const { accountDetails, walletAddress } = useSelector((state: RootState) => state.user);
  const { amount } = useSelector((state: RootState) => state.crypto);
  const { quote } = useSelector((state: RootState) => state.quote);
  const { fee } = useSelector((state: RootState) => state.fee);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getUser();
  }, []);

  const onClickContinue = () => {
    setClicked(true);

    dispatch(AppActions.order.createOffRampOrder({
      customerWalletAddress: walletAddress,
      type: 1, // OFF RAMP
      currency: currency,
      fiatAmount: getFiat(amount, quote, fee.fee, 1),
      crypto: crypto,
      cryptoAmount: amount,
      liquidityQuote: quote.liquidityQuotes,
      liquidityProvider: quote.liquidityProvider,
      processingFee: fee.fee,
      tokenId: card?.id || validatedCardTokenId,
      beneficiaryName: `${user?.firstName} ${user?.lastName}`,
      beneficiaryAccountNumber: walletAddress,
      beneficiaryOwnershipProofType: "N/A",
      beneficiaryOwnershipProof: "N/A",
      bankAccountDetail: {
        ...accountDetails
      },
      error: (message: string) => {
        setError(message);
      }
    }));
  }

  useEffect(() => {
    if (createdOrder && currentOrder) {
      if (confirmation) {
        navigate(`${PATHS.HISTORY}/${currentOrder.orderUuid}`);
      }
    }
  }, [createdOrder, currentOrder, confirmation]);

  const card = useMemo(() => {
    const method = searchParams.get('payment');
    const id = searchParams.get('id');
    if (method === 'card') {
      return cards.find(el => el.id === id);
    }
  }, [searchParams, cards]);

  const onCloseWarning = () => {
    setError('');
  };

  return (
    <>
      <Header title="Create Your Sell Order" />
      <div className="flex flex-col justify-between h-full p-4 mt-4">
        <div className="flex flex-col">
          <h1 className="text-2xl font-bold text-font-primary mb-4">Confirm Your Sell Order</h1>
          {
            !!error &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <img src={CancelInCircleSvg} />
              <p className="text-sm font-medium text-font-secondary ml-2">{error}</p>
            </div>
          }
          <span className="text-base font-medium text-font-subtle">Withdrawal Method</span>
          <div className="flex justify-between items-center mt-2 mb-8">
            {
              {
                ['bank']: <div className="flex items-center">
                  <div className="w-5 h-5 rounded-full bg-center bg-cover mr-4" style={{ backgroundImage: `url(${getCurrencySvg(currency)})` }} />
                  <div className="flex flex-col">
                    <span className="text-base font-medium text-font-primary">{currency} bank account</span>
                    {
                      getBankInputFields(currency).map(({label, name}: any) => (
                        <span className="text-sm font-medium text-font-primary">
                          {label}: {accountDetails[name]}
                        </span>
                      ))
                    }
                  </div>
                </div>,
                ['card']: <div className="flex items-center">
                  {
                    card && <>
                      {
                        {
                          ['Master']: <img src={PaymentLogo1} />,
                          ['Visa']: <img src={PaymentLogo2} />,
                          default: <img src={PaymentLogo3} />,
                        }[card.cardType]
                      }&nbsp;
                      <span className="text-base font-bold">{card.cardNumber}</span>
                    </>
                  }
                </div>
              }[searchParams.get('payment') || 'card']
            }
            <Link to={`${PATHS.SELL}${PATHS.BANK_DETAIL}`}><a className="text-sm font-bold text-primary">Change</a></Link>
          </div>
          <BuyCryptoSummary
            currency={currency}
            crypto={crypto}
            type={RAMPING.OFF}
            amount={amount}
          />
        </div>
        <div className="w-full mt-4">
          <div className="flex items-center mb-4">
            <CheckBox className="mr-4" error={!confirmation && clicked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setConfirmation(e.target.checked); }} />
            <p className="text-sm font-medium text-font-primary">I agree to FitechOne Sp.zoo.’s <a className="text-blue-500 underline" href={termsOfServiceLink} target='_blank'>Terms of Use</a>. Note that the exchange rate and amount are indicative only. Upon receipt of the Crypto amount the actual exchange rate will be set based on the market rate at that time. I authorise FitechOne Sp.zoo. to credit my chosen payment method for the resulting amount and understand that this order can not be cancelled, recalled or refunded.</p>
          </div>
          {
            !confirmation && clicked && <p className="text-sm font-medium text-feedback-warning mb-4">You must check this box to confirm your agreement with the Terms of Use to proceed.</p>
          }
          <Button
            className="w-full flex justify-center"
            onClick={onClickContinue}
            disabled={!confirmation || creatingOrder}
          >
            {
              !creatingOrder ?
              'Proceed with Order' :
              <div className={`flex items-center justify-center w-9 h-9 rounded-full z-20 animate-spin-slow`} >
                <PendingIcon width={36} height={36} />
              </div>
            }
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;