import { Link, } from 'react-router-dom';

import { PATHS } from "utils/consts";
import { ArrowRightSvg, FitechOneLogo, CancelSvg, ExitSvg, HelpSvg, PlusSvg, SettingSvg, MinusSvg } from "utils/icons";
import Button from "../common/buttons/Button";
import { useContext, useEffect } from 'react';
import { PlatformContext } from 'contexts';
import CryptoIcon from 'assets/icons/CrypotIcon';
interface IProps {
  currency: string;
  crypto: string;
  onClose: () => void;
}

const Menu: React.FC<IProps> = (props) => {
  const { onClose } = props;
  const { user, getUser, resetSession, getLoggedIn } = useContext(PlatformContext);

  const onLogOut = () => {
    resetSession();
    onClose();
    window.location.href = PATHS.BUY;
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4 overflow-auto">
      <div className="flex justify-between items-end mb-8">
        <label className="text-lg font-medium text-font-primary">{getLoggedIn() && `Hello ${user && !!user.firstName && user.firstName} ${user && !!user.lastName && user.lastName}!`}</label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      {
        !getLoggedIn() ? (
          <div className="flex flex-col justify-center">
            <div className="flex justify-center">
              <img className="mb-4" width="128px" src={FitechOneLogo} />
            </div>
            <span className="w-full px-8 text-center text-base font-medium text-font-primary">Your secure way to do Crypto On and Off Ramping.</span>
            <Button className="w-full mt-4 mb-4" onClick={() => { window.location.href = PATHS.LOGIN; onClose(); }}><>Log In</></Button>
            <span className="w-full text-center text-sm font-medium text-font-primary">Don’t have an account? <Link to={PATHS.REGISTER} className="text-primary">Sign up</Link></span>
          </div>
        ) :
          (
            <Link to={PATHS.HISTORY} onClick={() => { onClose(); }} className="flex justify-between items-center bg-feedback-information-tint rounded-xl p-5 w-full">
              <div className="flex flex-col items-start">
                <span className="text-base font-bold text-font-primary">Trade History</span>
                {/* <div className="flex items-center mt-2">
                  <div className="w-5 h-5 rounded-full bg-center bg-cover mr-2" style={{ backgroundImage: `url(${currencySvgs[currency]})` }} />
                  <span className="text-sm font-medium text-gray-500">(0.01739 BTC)</span>
                </div> */}
              </div>
              <img src={ArrowRightSvg} />
            </Link>
          )
      }
      <ul className="mt-8">
        {
          !getLoggedIn() &&
          <li className="mb-2">
            <Link to={PATHS.BUY} onClick={onClose} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
              <div className="flex items-center mt-2">
                <CryptoIcon />
                <span className="text-base font-medium text-font-primary ml-4">Buy / Sell Crypto</span>
              </div>
              <img src={ArrowRightSvg} />
            </Link>
          </li>
        }
        {
          getLoggedIn() &&
          <>
            <li className="mb-2">
              <Link to={PATHS.BUY} onClick={() => { onClose(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
                <div className="flex items-center mt-2">
                  <img src={PlusSvg} />
                  <span className="text-base font-medium text-font-primary ml-4">Buy</span>
                </div>
                <img src={ArrowRightSvg} />
              </Link>
            </li>
            <li className="mb-2">
              <Link to={PATHS.SELL} onClick={() => { onClose(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
                <div className="flex items-center mt-2">
                  <img src={MinusSvg} />
                  <span className="text-base font-medium text-font-primary ml-4">Sell</span>
                </div>
                <img src={ArrowRightSvg} />
              </Link>
            </li>
            {/* <li className="mb-2">
              <Link to={PATHS.MESSAGES} onClick={() => { onClose(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
                <div className="flex items-center mt-2">
                  <EmailIcon />
                  <span className="text-base font-medium text-font-primary ml-4">Messages</span>
                </div>
                <img src={ArrowRightSvg} />
              </Link>
            </li> */}
          </>
        }
        <li className="mb-2">
          <Link to={PATHS.SETTINGS} onClick={() => { onClose(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
            <div className="flex items-center mt-2">
              <img src={SettingSvg} />
              <span className="text-base font-medium text-font-primary ml-4">Settings</span>
            </div>
            <img src={ArrowRightSvg} />
          </Link>
        </li>
        <li className="mb-2">
          <Link to={PATHS.BUY} onClick={() => { onClose(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
            <div className="flex items-center mt-2">
              <img src={HelpSvg} />
              <span className="text-base font-medium text-font-primary ml-4">Help</span>
            </div>
            <img src={ArrowRightSvg} />
          </Link>
        </li>
        {getLoggedIn() && <li className="mb-2">
          <div onClick={() => { onLogOut(); }} className="flex justify-between items-center bg-surface-secondary rounded-xl p-5 w-full">
            <div className="flex items-center mt-2">
              <img src={ExitSvg} />
              <span className="text-base font-medium text-font-primary ml-4">Logout</span>
            </div>
            <img src={ArrowRightSvg} />
          </div>
        </li>}
      </ul>
    </div>
  );
};

export default Menu;